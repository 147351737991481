@media (min-width:320px) and (max-width:425px)  {
	/* smartphones, iPhone, portrait 480x320 phones */
  .isDesktop {
  		display:none !important;
  }
  .is-hide-mobile {
    display:none
  }
  .panelActive-width {
    width: 100% !important;
  }
  .full-width {
    width: 100% !important
  }
  .modal {
    .modal-content {
      &.is-medium {
        width: 50% !important
      }
    }
  }

  .headerWorkspaceHeadText {
    font-size:14px !important;
  }
  .priceListButtonText {
    font-size:12px !important
  }
}

@media (min-width:426px) and (max-width:768px)  {
	/* smartphones, iPhone, portrait 480x320 phones */
  .isDesktop {
  		display:none !important;
  }
  .is-hide-mobile {
    display:none
  }
  .panelActive-width {
    width: 100% !important;
  }
  .full-width {
    width: 100% !important
  }
  .modal {
    .modal-content {
      &.is-medium {
        width: 50% !important
      }
    }
  }
  .headerWorkspaceHeadText {
    font-size:16px !important;
  }
  .priceListButtonText {
    font-size:12px !important
  }
}

@media (min-width:1024px) {
	/* big landscape tablets, laptops, and desktops */
  .isMobile {
      display:none !important;
  }
  .is-hide-Desktop {
      display:none !important
  }
}
